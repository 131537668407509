import { Button, Dropdown, Form, Input, Menu, Select } from 'antd';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { gql } from '@apollo/client';
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
const Option = Select.Option;

const searchPatientsQuery = gql`
  query($filter: String!, $organizationId: ID!) {
    searchPatients(filter: $filter, organizationId: $organizationId) {
      id
      identifier
      givenName1
      givenName2
      givenName3
      familyName
      dob
      sex
      address1
      address2
      city
      state
      postalCode
      phone
      email
    }
  }
`;

interface Patient {
  id: string;
  identifier?: string;
  givenName1?: string;
  givenName2?: string;
  givenName3?: string;
  familyName?: string;
  dob?: string;
  sex?: string;
  address1?: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  phone?: string;
  email?: string;
}

interface Data {
  searchPatients: Patient[];
}

interface Variables {
  filter: string;
  organizationId: string;
}

interface Props {
  handleClearNewPatient: () => void;
  newPatient?: Patient;
  form: WrappedFormUtils;
  organization: {
    id: string;
    useClickToSearchForPatients: boolean;
  };
  style?: React.CSSProperties;
  onChange?: (arg0: Patient) => void;
  preselectedPatient?: string;
}

const formatPatientDisplay = (patient: Patient) => {
  const formattedName = `${patient.givenName1} ${patient.familyName}`;
  const formattedDob = patient.dob ? `${moment(patient.dob).format('MM/DD/YYYY')}` : '';
  const formattedMrn = patient.identifier ? `MRN: ${patient.identifier.slice(0, 15)}` : '';

  return {
    fullDisplay: `${formattedName} ${formattedDob} ${formattedMrn}`.trim(),
    name: formattedName,
    dob: formattedDob,
    mrn: formattedMrn,
  };
};

const PatientSearchField: React.FC<Props> = ({
  handleClearNewPatient,
  newPatient,
  form,
  organization,
  style,
  onChange,
}) => {
  const [options, setOptions] = useState<JSX.Element[]>([]);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [searchText, setSearchText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const client = useApolloClient();

  const createPatientOption = (patient: Patient): JSX.Element => {
    const { name, dob, mrn } = formatPatientDisplay(patient);
    return (
      <Option key={patient.id}>
        <div>
          {name}
          <span style={{ fontSize: 12, paddingLeft: 8 }}>{dob}</span>
          {mrn && <span style={{ fontSize: 12, color: '#aaa', float: 'right' }}>&nbsp;{mrn}</span>}
        </div>
      </Option>
    );
  };

  const handleSearch = async (patientName: string): Promise<void> => {
    handleClearNewPatient();
    const sanitizedPatientName = patientName.trim().replace(/\s+/g, ' ');
    if (sanitizedPatientName && sanitizedPatientName.length > 2) {
      const {
        data: { searchPatients: patients },
      } = await client.query<Data, Variables>({
        query: searchPatientsQuery,
        variables: { filter: sanitizedPatientName, organizationId: organization.id },
      });

      const newOptions: JSX.Element[] = [];
      patients.forEach(patient => {
        newOptions.push(createPatientOption(patient));
      });

      setOptions(newOptions);
      setPatients(patients);
    } else {
      setOptions([]);
      setPatients([]);
    }
  };

  const debounceHandleSearch = _.debounce(handleSearch, 300);

  const { getFieldDecorator } = form;
  const displayOptions = newPatient ? createPatientOption(newPatient) : options;
  useEffect(() => {
    if (newPatient) {
      const { fullDisplay } = formatPatientDisplay(newPatient);
      setSearchText(fullDisplay);
    }
  }, [newPatient]);
  const handleOptionSelect = (patient: Patient) => {
    if (onChange) {
      onChange(patient);
    }
    const { fullDisplay } = formatPatientDisplay(patient);
    setSearchText(fullDisplay);
    setDropdownVisible(false);
  };

  const handleClickSearch = async (): Promise<void> => {
    setIsLoading(true);
    await handleSearch(searchText);
    setDropdownVisible(true);
    setIsLoading(false);
  };

  const searchComponent = organization.useClickToSearchForPatients ? (
    <div style={{ position: 'relative' }}>
      <div style={{ display: 'flex', gap: '8px' }}>
        <Input
          placeholder="Patient Name"
          allowClear
          value={newPatient ? formatPatientDisplay(newPatient).fullDisplay : searchText}
          onChange={e => {
            setSearchText(e.target.value);
            setDropdownVisible(false);
            if (!e.target.value) {
              if (newPatient) {
                handleClearNewPatient();
              }
              form.setFieldsValue({ patientSearchField: null });
            }
          }}
          disabled={isLoading}
        />
        <Button
          type="primary"
          onClick={handleClickSearch}
          loading={isLoading}
          disabled={isLoading || searchText.length < 3}
        >
          Search
        </Button>
      </div>

      <Dropdown
        visible={dropdownVisible}
        overlay={
          <Menu style={{ maxHeight: '300px', overflow: 'auto' }}>
            {patients.length > 0 ? (
              patients.map(patient => {
                const { name, dob, mrn } = formatPatientDisplay(patient);
                return (
                  <Menu.Item key={patient.id} onClick={() => handleOptionSelect(patient)}>
                    {name}
                    <span style={{ fontSize: 12, paddingLeft: 8 }}>{dob}</span>
                    {mrn && <span style={{ fontSize: 12, color: '#aaa', float: 'right' }}>&nbsp;{mrn}</span>}
                  </Menu.Item>
                );
              })
            ) : (
              <Menu.Item disabled>No patients found</Menu.Item>
            )}
          </Menu>
        }
        trigger={['click']}
        onVisibleChange={visible => setDropdownVisible(visible)}
      >
        <div style={{ position: 'absolute', left: 0, right: 0 }} />
      </Dropdown>
    </div>
  ) : (
    <Select
      showSearch
      placeholder="Patient Name"
      style={style}
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={debounceHandleSearch}
      onChange={value => {
        const patient = patients.find(p => p?.id === value);
        if (patient && onChange) {
          onChange(patient);
        }
      }}
      notFoundContent={null}
    >
      {displayOptions}
    </Select>
  );

  return (
    <Form.Item label="What's the patient's name?" help="Type at least 3 letters to search">
      {getFieldDecorator('patientSearchField', {
        validateTrigger: organization.useClickToSearchForPatients ? 'onChange' : 'onBlur',
        rules: [
          {
            required: true,
            message: 'Required',
          },
        ],
      })(searchComponent)}
    </Form.Item>
  );
};

export default PatientSearchField;
