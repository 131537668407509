import * as Sentry from '@sentry/browser';
import { Form, message, Select, Button } from 'antd';
import React, { FC, useState } from 'react';
import { useMutation } from '@apollo/client';
import { UpdateReferralStatus } from '../../../../GraphQL';
import { deletePaginatedCacheItems } from '../../../../helpers/cache';
import { capitalize } from '../../../../helpers/stringHelpers';
import { ReferralData, QUERY } from './GetReferralQuery';
import { CancelReferral } from './CancelReferral';
import { RejectReferral } from './RejectReferral';
import { NoShowReferral } from './NoShowReferral';
import { CompleteReferral } from './CompleteReferral';
import { useAppContext } from '../../../../hooks';
import { isBlockitAdmin } from '../../../core/components/PrivateRoute';
import { LIST_AUDIT_LOG_QUERY } from './ListAuditLogQuery';

const { Option } = Select;

interface UpdateReferralStatusMutationVars {
  referralStatusInput: {
    id: string;
    status: string;
  };
}

interface UpdateReferralStatusMutationData {
  UpdateReferralStatus: {
    id: string;
    status: string;
  };
}

export const ChangeReferralStatus: FC<{ referral?: ReferralData }> = ({ referral }): JSX.Element | null => {
  const [updateReferralStatusMutation, { error }] = useMutation<
    UpdateReferralStatusMutationData,
    UpdateReferralStatusMutationVars
  >(UpdateReferralStatus);

  if (error) {
    console.error(error);
    Sentry.captureException(error);
    message.error('Error updating appointment status');
  }

  const terminalStatuses = ['completed', 'cancelled', 'noshow', 'rejected'];

  const inTerminalStatus = (status: string | undefined): boolean => {
    if (status) {
      return terminalStatuses.includes(status);
    } else {
      return false;
    }
  };

  const [isStatusOverriden, setIsStatusOverriden] = useState(false);

  const statusOverridenToFalse = (): void => {
    setIsStatusOverriden(false);
  };

  const terminalStatusButtons = (): JSX.Element | null => {
    if (!inTerminalStatus(referral?.status) || (inTerminalStatus(referral?.status) && isStatusOverriden)) {
      return (
        <>
          <RejectReferral referral={referral} onComplete={statusOverridenToFalse} />
          <CancelReferral referral={referral} onComplete={statusOverridenToFalse} />
          <NoShowReferral referral={referral} onComplete={statusOverridenToFalse} />
          <CompleteReferral referral={referral} onComplete={statusOverridenToFalse} />
        </>
      );
    } else if (inTerminalStatus(referral?.status) && !isStatusOverriden) {
      return <Button onClick={() => setIsStatusOverriden(true)}>Override Status</Button>;
    } else {
      return null;
    }
  };

  const humanizeStatus = (status: string): string => {
    switch (status.toLowerCase()) {
      case 'patient_seen':
        return 'Patient Seen';
      case 'noshow':
        return 'No Show';
      default:
        return capitalize(status);
    }
  };
  const { currentOrganization } = useAppContext();
  if (
    !isBlockitAdmin(currentOrganization) &&
    currentOrganization?.id === referral?.provider?.organization?.id &&
    currentOrganization?.id !== referral?.profileReferral?.profile?.organization?.id &&
    currentOrganization?.id !== referral?.patientReferral?.profile?.organization?.id
  ) {
    return null;
  }

  if (
    referral?.nextStatuses &&
    referral?.nextStatuses.length >= 1 &&
    (!referral?.appointment || (referral?.appointment && referral?.appointment?.status === 'fulfilled'))
  ) {
    return (
      <Form>
        <Form.Item label="Change Status">
          <Select<string>
            size="small"
            value={humanizeStatus(referral.status)}
            placeholder="Update Status"
            style={{}}
            onChange={val =>
              referral
                ? updateReferralStatusMutation({
                    variables: {
                      referralStatusInput: { id: referral.id, status: val },
                    },
                    refetchQueries: [
                      {
                        query: QUERY,
                        variables: {
                          id: referral.id,
                          organizationId: currentOrganization?.id as string,
                        },
                      },
                      {
                        query: LIST_AUDIT_LOG_QUERY,
                        variables: {
                          action: 'referral.status.updated',
                          recordId: referral?.id,
                          organizationId: currentOrganization?.id as string,
                        },
                      },
                    ],
                    update: cache => {
                      message.success('Referral status updated');
                      deletePaginatedCacheItems(cache, 'ListReferrals');
                      deletePaginatedCacheItems(cache, 'ListAppointments');
                    },
                  })
                : message.error('Unable to update referral status')
            }
          >
            {referral?.nextStatuses.map((status, _i) => {
              if (terminalStatuses.includes(status)) {
                return null;
              } else {
                return (
                  <Option value={status} key={status}>
                    {humanizeStatus(status)}
                  </Option>
                );
              }
            })}
          </Select>
          {terminalStatusButtons()}
        </Form.Item>
      </Form>
    );
  } else {
    return null;
  }
};
