import _ from 'lodash';
import React, { FC, useState } from 'react';
import store from '../../../localstore';
import { AppContext } from '../contexts/AppContext';
import { Viewer, ViewerLocation, ViewerOrganization } from '../../viewer';

const initialLocationForCurrentOrganization = (organization: ViewerOrganization | null): ViewerLocation | null => {
  if (organization && !organization.locations) {
    return null;
  }
  const currentLocationId = store().getItem('currentLocationId');

  const currentLocation =
    currentLocationId && matchLocation(currentLocationId, organization?.locations)
      ? matchLocation(currentLocationId, organization?.locations)
      : organization?.locations[0];

  if (currentLocation) {
    store().setItem('currentLocationId', currentLocation.id);
  }

  return currentLocation || null;
};

const matchLocation = (
  currentLocationId: string,
  locations: ViewerLocation[] | undefined
): ViewerLocation | undefined => {
  return _.find(locations, l => {
    return l.id === currentLocationId;
  });
};

const matchOrganization = (
  currentOrganizationId: string,
  organizations: ViewerOrganization[]
): ViewerOrganization | undefined => {
  return _.find(organizations, o => {
    return o.id === currentOrganizationId;
  });
};

const isUuid = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i;

export const AppState: FC = ({ children }): JSX.Element => {
  const [currentLocation, setCurrentLocation] = useState<ViewerLocation | null>(null);
  const [currentOrganization, setCurrentOrganization] = useState<ViewerOrganization | null>(null);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [mode, setMode] = useState<'referral' | 'admin'>('referral');
  const [viewer, setViewer] = useState<Viewer | null>(null);

  const getModeFromURL = (): 'referral' | 'admin' | null => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const mode = params.get('mode');

    if (mode === 'admin' || mode === 'referral') {
      return mode;
    }
    return null;
  };

  const handleSetCurrentLocation = (location: ViewerLocation | null): void => {
    if (location) {
      store().setItem('currentLocationId', location.id);
    }

    setCurrentLocation(location);
  };

  const handleSetViewer = (viewer: Viewer | null): void => {
    setViewer(viewer);
    if (!viewer) {
      return;
    }
    const organization = setAndGetCurrentOrganizationFromUrl(viewer);
    handleSetCurrentOrganization(organization);
    handleSetCurrentLocation(initialLocationForCurrentOrganization(organization));
  };

  const handleSetCurrentOrganization = (organization: ViewerOrganization | null): void => {
    const location = initialLocationForCurrentOrganization(organization);
    handleSetCurrentLocation(location);

    if (organization) {
      store().setItem('currentOrganizationId', organization.id);
    }

    setCurrentOrganization(organization);
  };

  const setAndGetCurrentOrganizationFromUrl = ({ organizations }: Viewer): ViewerOrganization => {
    const path = window.location.pathname;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const queryOrgId = params.get('organizationId');
    const regexes = ['/organizations/([0-9a-fA-F-]+)', '/referrals/([0-9a-fA-F-]+)'];

    let organizationId;
    // First check if organizationId is in query params
    if (queryOrgId && queryOrgId.match(isUuid)) {
      organizationId = queryOrgId;
    } else if (path.match(regexes[0])) {
      organizationId = path.match(regexes[0])?.[1];
    } else if (path.match(regexes[1])) {
      const parts = path.split('/');
      const potentialOrganizationId = parts[4];
      // If the parts match the referral pattern and the ID is a valid UUID, use it
      if (parts[1] === 'referrals' && potentialOrganizationId && potentialOrganizationId.match(isUuid)) {
        organizationId = potentialOrganizationId;
      } else {
        // Otherwise, get the organization ID from session storage or local storage
        organizationId =
          window.sessionStorage.getItem('currentOrganizationId') || store().getItem('currentOrganizationId');
      }
    } else {
      organizationId =
        window.sessionStorage.getItem('currentOrganizationId') || store().getItem('currentOrganizationId');
    }

    let organization = organizations[0];
    if (organizationId) {
      store().setItem('currentOrganizationId', organizationId);
      const matchedOrg = matchOrganization(organizationId, organizations);
      if (matchedOrg) {
        organization = matchedOrg;
      }
    }
    setCurrentOrganization(organization);
    return organization;
  };

  const setCurrentOrganizationFromReferralUrl = (
    organizationIds: [string | undefined, string | undefined],
    { organizations }: Viewer
  ): void => {
    for (const organizationId of organizationIds) {
      if (organizationId) {
        const organization = organizations.find(o => o.id === organizationId);
        if (organization) {
          store().setItem('currentOrganizationId', organization.id);
          setCurrentOrganization(organization);
          break; // Exit the loop once the organization is found
        }
      }
    }
  };

  const init = (initialViewer: Viewer): void => {
    const modeFromURL = getModeFromURL();
    if (modeFromURL) {
      setMode(modeFromURL);
    }
    const organization = currentOrganization ? currentOrganization : setAndGetCurrentOrganizationFromUrl(initialViewer);
    const location = currentLocation ? currentLocation : initialLocationForCurrentOrganization(organization);
    setViewer(initialViewer);

    if (organization) {
      setCurrentLocation(location);
      setCurrentOrganization(organization);
    } else {
      window.location.href = '/account-signup/subscribe';
    }
  };

  const reset = (): void => {
    setCurrentLocation(null);
    setCurrentOrganization(null);
    setIsAuthenticated(false);
    setMode('referral');
    setViewer(null);
  };

  const switchMode = (newMode: 'referral' | 'admin'): void => {
    setMode(newMode);
  };

  return (
    <AppContext.Provider
      value={{
        currentLocation,
        currentOrganization,
        init,
        isAuthenticated,
        mode,
        reset,
        setCurrentOrganizationFromReferralUrl,
        setCurrentLocation: handleSetCurrentLocation,
        setCurrentOrganization: handleSetCurrentOrganization,
        setIsAuthenticated,
        setMode,
        setViewer: handleSetViewer,

        // todo - refactor to setMode
        switchMode,

        viewer,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
