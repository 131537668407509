import { Button, Card, Form, message, Typography, Row, Col, Switch } from 'antd';
import { Option, Select } from '../../../system/components/Select';
import React, { FC, FormEvent } from 'react';
import { getOrganization, useGetOrganizationQuery } from '../../../hooks/queries/useGetOrganizationQuery';
import { FormComponentProps } from 'antd/lib/form';
import { useUpdateOrganizationMutation } from '../../../hooks/mutations/useUpdateOrganization';
import { BlockRestrictedUsers } from '../../../helpers';

interface Props extends FormComponentProps {
  organizationId: string;
}

interface ConsumerSettingsFormData {
  showInsuranceFilters: boolean;
  showInsuranceFiltersDuringScheduling: boolean;
  searchProfilesWithoutScheduling: boolean;
  hideEmailFieldInReferral: boolean;
  showSendToPatient: boolean;
  useClickToSearchForPatients: boolean;
  defaultReferralSearchRadius: number | null;
  defaultReferralSort: string;
}

const ReferralWorkflowCardComponent: FC<Props> = ({ form, organizationId }): JSX.Element => {
  const [updateOrganizationSettings] = useUpdateOrganizationMutation();
  const { data } = useGetOrganizationQuery({ variables: { organizationId: organizationId } });

  const organization = data?.getOrganization;

  const handleSubmit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    form.validateFields(async (err, values: ConsumerSettingsFormData) => {
      try {
        await updateOrganizationSettings({
          variables: {
            organizationId: organizationId,
            updateOrganizationInput: {
              showInsuranceFilters: values.showInsuranceFilters,
              showInsuranceFiltersDuringScheduling: values.showInsuranceFiltersDuringScheduling,
              searchProfilesWithoutScheduling: values.searchProfilesWithoutScheduling,
              showSendToPatient: values.showSendToPatient,
              defaultReferralSearchRadius: values.defaultReferralSearchRadius || null,
              defaultReferralSort: values.defaultReferralSort,
              hideEmailFieldInReferral: values.hideEmailFieldInReferral,
              useClickToSearchForPatients: values.useClickToSearchForPatients,
            },
          },
          refetchQueries: [{ query: getOrganization, variables: { organizationId: organizationId } }],
        });
        message.success('Your changes have been saved.');
      } catch (err) {
        message.error('An error occurred while saving your changes');
      }
    });
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Card
        title="Referral Search Settings"
        extra={
          <BlockRestrictedUsers>
            <Button htmlType="submit" type="primary">
              Save Referral Search Settings
            </Button>
          </BlockRestrictedUsers>
        }
      >
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Insurance filters">
              {form.getFieldDecorator('showInsuranceFilters')(
                <Switch defaultChecked={organization?.showInsuranceFilters} />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Enable/disable the insurance filters on the referral search page
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Insurance select for Buffer">
              {form.getFieldDecorator('showInsuranceFiltersDuringScheduling')(
                <Switch defaultChecked={organization?.showInsuranceFiltersDuringScheduling} />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Enable/disable insurance select on the Schedule a Patient page. This is used to determine the correct
              dynamic slot buffer for the profiles.
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Show profiles without scheduling">
              {form.getFieldDecorator('searchProfilesWithoutScheduling')(
                <Switch defaultChecked={organization?.searchProfilesWithoutScheduling} />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Enable/disable showing profiles that do not have scheduling
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Enable send-to-patient scheduling">
              {form.getFieldDecorator('showSendToPatient')(<Switch defaultChecked={organization?.showSendToPatient} />)}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Enable/disable showing send-to-patient option for referral scheduling
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Set default search radius">
              {form.getFieldDecorator('defaultReferralSearchRadius', {
                initialValue: organization?.defaultReferralSearchRadius || '',
              })(
                <Select placeholder="Select Default Radius">
                  <Option value={''} key="any">
                    Any Miles
                  </Option>
                  <Option value={5} key="5">
                    5
                  </Option>
                  <Option value={15} key="15">
                    15
                  </Option>
                  <Option value={25} key="25">
                    25
                  </Option>
                  <Option value={50} key="50">
                    50
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Sets the default search radius used on the referral search results
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Set default sort">
              {form.getFieldDecorator('defaultReferralSort', { initialValue: organization?.defaultReferralSort })(
                <Select placeholder="Select Default Sort">
                  <Option value="distance" key="distance">
                    Distance
                  </Option>
                  <Option value="next_availability" key="next_availability">
                    Next Availability
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Sets the default sort used on the referral search results
            </Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Hide email field in referral">
              {form.getFieldDecorator('hideEmailFieldInReferral')(
                <Switch defaultChecked={organization?.hideEmailFieldInReferral} />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">Enable/disable the email field in the referral form</Typography.Text>
          </Col>
        </Row>
        <Row align="top">
          <Col span={12}>
            <Form.Item label="Click to Search for patients">
              {form.getFieldDecorator('useClickToSearchForPatients')(
                <Switch defaultChecked={organization?.useClickToSearchForPatients} />
              )}
            </Form.Item>
          </Col>
          <Col span={10} push={1}>
            <Typography.Text type="secondary">
              Enable/disable using Click to Search for patients on the referral search page. This may help with longer
              response times to get patient search results.
            </Typography.Text>
          </Col>
        </Row>
      </Card>
    </Form>
  );
};

export const ReferralWorkflowCard = Form.create<Props>({ name: 'Workflow Settings' })(ReferralWorkflowCardComponent);
