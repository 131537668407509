import { gql } from '@apollo/client';

export const GetViewerOrganization = gql`
  query ViewerOrganization($organizationId: ID!) {
    viewerOrganization(organizationId: $organizationId) {
      id
      name
      slug
      role
      isActive
      isFree
      useJumpq
      defaultReferralSearchRadius
      defaultDateFilterRange
      defaultReferralSort
      referralSettings {
        enableSearchLinkWhenCanceled
        enableCancelAndRescheduleButton
        hideReferralOrder
        hideSchedulingButtons
      }
      isCovid
      hasSso
      accessCenterProfileId
      showInsuranceFilters
      showSendToPatient
      hideEmailFieldInReferral
      useClickToSearchForPatients
      region {
        id
        name
      }
      locations {
        id
        name
        sends
        receives
      }
      featureFlags {
        id
        name
      }
      freePlanConfiguration {
        id
        name
        appointmentsPerWeek
        locations
        profiles
        providers
        users
      }
      cancelReasons {
        key
        value
      }
      referralCancelReasons {
        key
        value
      }
      referralRejectionReasons {
        key
        value
      }
      consumerSchedulingSettings {
        noAvailabilityText
      }
    }
  }
`;

export const GetSimplifiedViewer = gql`
  query Viewer($skip_locations: Boolean) {
    viewer(skip_locations: $skip_locations) {
      id
      name
      email
      blockitAdmin
      organizations {
        id
        name
        role
        isActive
      }
    }
  }
`;
